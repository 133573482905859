<template>
  <!-- 已支付 -->
  <div>
    <div class="blue-box">
      <p>药品已购买，如有需要可申请续方再次购买</p>
    </div>
    <div class="page-box">
      <!--      <div class="card-box">-->
      <!--        <div class="title">-->
      <!--          收货人信息-->
      <!--        </div>-->
      <!--        <div class="mt20 size28 bold dark-color">-->
      <!--          <span class="mr20">木子李</span>-->
      <!--          <span class="mr20">先生</span>-->
      <!--          <span class="mr20">13283758987</span>-->
      <!--        </div>-->
      <!--        <div class="desc">-->
      <!--          汤臣一品-西门  1号楼66层101室-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="card-box">
        <div class="title">
          药品信息
        </div>
        <zyDrugItem v-if="dataList.cflx ==='2'" :data="dataList" :list="dataList.preDetails" />
        <drugItem v-else :data="dataList.preDetails" />
      </div>
      <div class="card-box">
        <div class="title">
          开方信息
        </div>
        <div class="row mt20">
          <div class="left">
            开方医师：
          </div>
          <div class="right">
            {{ dataList.kfysxm }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            审方医师：
          </div>
          <div class="right">
            {{ dataList.ysxm }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            开方时间：
          </div>
          <div class="right">
            {{ dataList.kfsj }}
          </div>
        </div>
        <div class="row">
          <div class="left">
            审方时间：
          </div>
          <div class="right">
            {{ dataList.sfsj }}
          </div>
        </div>
      </div>
      <!--      <div class="card-box">-->
      <!--        <div class="title">-->
      <!--          订单信息-->
      <!--        </div>-->
      <!--        <div class="row mt20">-->
      <!--          <div class="left">-->
      <!--            订单编号：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            23828476877122-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="left">-->
      <!--            复诊流水号：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            23828476877122-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="left">-->
      <!--            创建时间：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            2020-01-12  12:28:23-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="left">-->
      <!--            支付时间：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            2020-01-12  13:26:22-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="left">-->
      <!--            支付总额：-->
      <!--          </div>-->
      <!--          <div class="right red-color">-->
      <!--            ¥ 50.00-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="card-box">-->
      <!--        <div class="title">-->
      <!--          物流信息-->
      <!--        </div>-->
      <!--        <div class="row mt20">-->
      <!--          <div class="left">-->
      <!--            物流公司：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            顺丰快递-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="row">-->
      <!--          <div class="left">-->
      <!--            物流单号：-->
      <!--          </div>-->
      <!--          <div class="right">-->
      <!--            23828476877-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import {getItem} from "@/utils/cache"
export default {
  name: 'Paid',
  data() {
    return {
      dataList: {
      }
    }
  },
  mounted() {
    this.dataList = getItem('prescriptionItem')
    console.log(this.dataList)
  },
  methods: {}
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.blue-box {
  width: 100%;
  height: 168px;
  background: @theme-color;
  p{
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;
  }
}

.page-box {
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -82px;

  .card-box {
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    padding: 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    z-index: 2;

    .title {
      font-size: 32px;
      font-weight: bold;
      color: @dark-font-color;
    }

    .desc {
      font-size: 26px;
      color: #666666;
      margin: 12px 0px;
    }

    .row {
      display: flex;
      line-height: 60px;
      justify-content: space-between;
      color: @dark-font-color;
      font-size: 28px;

      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }
    }

    .mr20 {
      margin-right: 20px;
    }
  }

}
</style>
